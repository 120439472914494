import BaseBean from "@/utils/BaseBean";

export interface IImageSource {
    selImageName:string//当前选择的图片名称
    selImagePath:string //当前选择的图片地址
    arrayPath: Array<string>//可以选择的图片素材
}
export interface IImageManageDataObj {
    utilInst:ImageManageUtil
    refMap:Map<string,any>
    fullscreenLoading:boolean
    dialogVisible:boolean//查看图片详情弹出框影藏显示控制标志
    curImag:ResourceFileBean
    uploadParams:any
    drawer: boolean
    gridData: Array<ResourceFileBean>
    contentAreaHeight:number
    tabMaxHeight:number
    imageSource:IImageSource
}
export default class ImageManageUtil extends BaseBean{
    public dataObj:IImageManageDataObj

    constructor(proxy:any,dataObj:IImageManageDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //拿到系统图片资源
    public async getImageData():Promise<void>{
        this.dataObj.imageSource.arrayPath.length=0;//先清空
        this.dataObj.fullscreenLoading=true;
        //加载资源素材,为表格数据和图片数组赋值
        let _imageSource=sessionStorage.getItem("imageSource") as string;
        let imageSource:Array<ResourceFileBean>= JSON.parse(this.utils.UtilPub.decrypt(_imageSource));
        imageSource.forEach((item:ResourceFileBean)=> {
            this.dataObj.imageSource.arrayPath.push(item.path);
        });
        if(this.dataObj.imageSource.arrayPath.length!=0)this.dataObj.imageSource.selImagePath=this.dataObj.imageSource.arrayPath[0];
        this.dataObj.gridData=imageSource;
        this.dataObj.fullscreenLoading=false;
    }
}